import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Stack, useTheme } from '@mui/material'
import { type MenuOption, NavBar } from '@r40cap/ui'

import pages from './pages.json'
import { getMode } from './features/theme/themeSlice'
import { ThemeToggle } from './features/theme/ThemeToggle'
import type { PermissionsContextType } from './sdk/usePermissions'
import usePermissions from './sdk/usePermissions'
import ToRpmsButton from './components/utils/ToRpmsButton'
import PageWrapper from './components/utils/PageWrapper'
import ControlsPanel from './components/utils/ControlsPanel'
import CounterpartyBody from './components/CounterpartyBody'
import ConfigBody from './components/ConfigBody'
import AddAccountModal from './components/AddAccountModal'
import AlertsBody from './components/AlertsBody'
import AddAlertModal from './components/AddAlertModal'
import DeleteAlertModal from './components/DeleteAlertModal'
import DeribitBody from './components/deribit/DeribitBody'

import { getCoinbaseHealthScore, getOkxHealthScore, getStoneXHealthScore } from './healthFunctions'
import {
  collateralColumns as coinbaseCollateralColumns,
  positionColumns as coinbasePositionColumns
} from './constants/coinbasePrime'
import {
  collateralColumns as okxCollateralColumns,
  positionColumns as okxPositionColumns
} from './constants/okx'
import {
  collateralColumns as stoneXCollateralColumns,
  positionColumns as stoneXPositionColumns
} from './constants/stoneX'
import { PositionItem } from './sdk/types'

function userCanAccess (menuOption: MenuOption, permissionsContext: PermissionsContextType): boolean {
  switch (menuOption.slug) {
    case 'cbp':
      return permissionsContext.hasCoinbaseAccess
    case 'snex':
      return permissionsContext.hasStonexAccess
    case 'okx':
      return permissionsContext.hasOkxAccess
    case 'deribit':
      return permissionsContext.hasDeribitAccess
    case 'alerts':
      return permissionsContext.hasAlertsAccess
    default:
      return permissionsContext.hasConfigAccess
  }
}

function PageHandler () {
  const { palette } = useTheme()
  const mode = useSelector(getMode)
  const permissionsContext = usePermissions()
  const location = useLocation()

  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const [shockModalOpen, setShockModalOpen] = useState<boolean>(false)
  const splitPath = location.pathname.split('/')
  const viewType = splitPath[1]

  useEffect(() => {
    setHasEdited(false)
  }, [viewType])

  let redirectPath = '/snex'
  if (permissionsContext.hasOkxAccess) {
    redirectPath = '/okx'
  } else if (permissionsContext.hasCoinbaseAccess) {
    redirectPath = '/cbp'
  } else if (permissionsContext.hasDeribitAccess) {
    redirectPath = '/deribit'
  }

  return (
    <div style={{ backgroundColor: palette.background.paper }}>
      <NavBar
        pages={pages.filter((page) => userCanAccess(page, permissionsContext))}
        mode={mode}
        activeColor={palette.accent.main}
        nonActiveColor={palette.tableBodyText.main}
        color={palette.primary.main}
        logoColor={palette.logoColor.main}
      >
        <Stack direction='row' spacing={1}>
          {
            permissionsContext.hasRpmsAccess as boolean && <ToRpmsButton />
          }
          <ThemeToggle />
        </Stack>
      </NavBar>
      <PageWrapper>
        <Stack
          direction='column'
          spacing={1}
          sx={{
            width: '100%',
            paddingTop: '90px',
            paddingBottom: '10px',
            height: 'calc(100% - 90px - 10px)'
          }}
        >
          <ControlsPanel
            hasEdited={hasEdited}
            setHasEdited={setHasEdited}
            setShockModalOpen={setShockModalOpen}
          />
          <Routes>
            <Route path="/" element={<Navigate to={redirectPath} replace />}/>
            <Route
              path="cbp/:accountId?"
              element={<CounterpartyBody
                requiredPermission={'hasCoinbaseAccess'}
                getHealthScore={getCoinbaseHealthScore}
                collateralColumns={coinbaseCollateralColumns}
                positionColumns={coinbasePositionColumns}
                setHasEdited={setHasEdited}
                shockModalOpen={shockModalOpen}
                setShockModalOpen={setShockModalOpen}
                getFrozenValue={() => 0}
              />}
            />
            <Route
              path="okx/:accountId?"
              element={<CounterpartyBody
                requiredPermission={'hasOkxAccess'}
                getHealthScore={getOkxHealthScore}
                collateralColumns={okxCollateralColumns}
                positionColumns={okxPositionColumns}
                setHasEdited={setHasEdited}
                shockModalOpen={shockModalOpen}
                setShockModalOpen={setShockModalOpen}
                showMmr
                showDiscoutedMv
                showTotalGrossNotional
                showTotalMv
                getFrozenValue={(psns: readonly PositionItem[]) => {
                  return psns.reduce((acc, psn) => {
                    return acc + Math.abs(psn.price * (psn.quantity + (psn.riskOffsetInfo?.riskOffsetAmount ?? 0))) * psn.marginRequirementCoefficient
                  }, 0) * 1.3
                }}
              />}
            />
            <Route
              path="snex/:accountId?"
              element={<CounterpartyBody
                requiredPermission={'hasStonexAccess'}
                getHealthScore={getStoneXHealthScore}
                collateralColumns={stoneXCollateralColumns}
                positionColumns={stoneXPositionColumns}
                setHasEdited={setHasEdited}
                shockModalOpen={shockModalOpen}
                setShockModalOpen={setShockModalOpen}
                getFrozenValue={() => 0}
              />}
            />
            <Route
              path="deribit/:accountId?"
              element={<DeribitBody
                setHasEdited={setHasEdited}
                shockModalOpen={shockModalOpen}
                setShockModalOpen={setShockModalOpen}
              />}
            />
            <Route
              path="alerts"
              element={<AlertsBody setHasEdited={setHasEdited}/>}
            >
              <Route path={'new'} element={<AddAlertModal />} />
              <Route path={'delete/:alertId'} element={<DeleteAlertModal />} />
            </Route>
            <Route
              path="config"
              element={<ConfigBody setHasEdited={setHasEdited}/>}
            >
              <Route path={'new'} element={<AddAccountModal />} />
            </Route>
            <Route path="/*" element={<Navigate to={redirectPath} replace />} />
          </Routes>
        </Stack>
      </PageWrapper>
    </div>
  )
}

export default PageHandler
