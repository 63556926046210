import React, { useState } from 'react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { NumberInput, StringInput } from '@r40cap/ui'

import type {
  Account,
  AlertDeliveryMethod,
  AlertType,
  AlertSide,
  Counterparty
} from '../../sdk/types'
import CounterpartyInput from '../../sdk/CounterpartyInput'
import AccountInput from '../../sdk/AccountInput'
import AlertDeliveryMethodInput from '../../sdk/AlertDeliveryMethodInput'
import AlertTypeInput from '../../sdk/AlertTypeInput'
import AlertSideInput from '../../sdk/AlertSideInput'

export function NumberEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseFloat(valueString)
      submit(result)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <NumberInput
          title={title}
          intOnly={false}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          defaultPrecision={1}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function IntegerEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = Math.abs(parseInt(valueString))
      submit(result)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <NumberInput
          title={title}
          intOnly={true}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          defaultPrecision={1}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function StringEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: string) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [value, setValue] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (value !== '') {
      submit(value)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <StringInput
          title={title}
          value={value}
          setValue={setValue}
          color={palette.accent.main}
          enterSubmit={handleEnterSubmission}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function CounterpartyEditContent (props: {
  closeModal: () => void
  submit: (value: Counterparty) => void
}): React.JSX.Element {
  const { submit, closeModal } = props
  const [counterparty, setCounterparty] = useState<Counterparty | null>(null)
  const { palette } = useTheme()

  const handleSubmission = (): void => {
    if (counterparty !== null) {
      submit(counterparty)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <CounterpartyInput
          setCounterparty={setCounterparty}
          mainColor={palette.accent.main}
          secondaryColor={palette.background.default}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function AccountEditContent (props: {
  closeModal: () => void
  submit: (value: Account) => void
}): React.JSX.Element {
  const { submit, closeModal } = props
  const [account, setAccount] = useState<Account | null>(null)
  const { palette } = useTheme()

  const handleSubmission = (): void => {
    if (account !== null) {
      submit(account)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <AccountInput
          setAccount={setAccount}
          mainColor={palette.accent.main}
          secondaryColor={palette.background.default}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function DeliveryMethodEditContent (props: {
  closeModal: () => void
  submit: (value: AlertDeliveryMethod) => void
}): React.JSX.Element {
  const { submit, closeModal } = props
  const [method, setMethod] = useState<AlertDeliveryMethod>('fink_gpt')
  const { palette } = useTheme()

  const handleSubmission = (): void => {
    if (method !== null) {
      submit(method)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '20vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <AlertDeliveryMethodInput
          deliveryMethod={method}
          setDeliveryMethod={setMethod}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function AlertTypeEditContent (props: {
  closeModal: () => void
  submit: (value: AlertType) => void
}): React.JSX.Element {
  const { submit, closeModal } = props
  const [type, setType] = useState<AlertType>('live')
  const { palette } = useTheme()

  const handleSubmission = (): void => {
    submit(type)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '20vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <AlertTypeInput
          type={type}
          setType={setType}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function AlertSideEditContent (props: {
  closeModal: () => void
  submit: (value: AlertSide) => void
}): React.JSX.Element {
  const { submit, closeModal } = props
  const [side, setSide] = useState<AlertSide>('under')
  const { palette } = useTheme()

  const handleSubmission = (): void => {
    submit(side)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <AlertSideInput
          side={side}
          setSide={setSide}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function PercentageEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseFloat(valueString)
      submit(result / 100)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw', margin: '1vw' }}
    >
      <Stack direction={'column'} spacing={2}>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            alignItems: 'center',
            color: palette.tableBodyText.main
          }}
        >
          <NumberInput
            title={title}
            intOnly={false}
            valueString={valueString}
            setValueString={setValueString}
            submit={handleEnterSubmission}
            defaultValue={0}
            defaultPrecision={1}
            color={palette.accent.main}
          />
          <Typography>
            %
          </Typography>
        </Stack>
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '50%',
            alignSelf: 'center'
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
