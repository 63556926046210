import type { CollateralItem, PositionItem } from './sdk/types'

export function getCoinbaseHealthScore (collateral: readonly CollateralItem[], positions: readonly PositionItem[]): number {
  const equity = collateral.reduce((acc, bal) => acc + bal.price * bal.haircut * bal.multiplier * bal.balance, 0)
  const marginReq = positions.reduce((acc, pos) => acc + pos.price * pos.quantity * pos.multiplier * pos.marginRequirementCoefficient, 0)
  return equity / marginReq
}

export function getOkxHealthScore (collateral: readonly CollateralItem[], positions: readonly PositionItem[]): number {
  const equity = collateral.reduce((acc, bal) => acc + bal.price * bal.haircut * bal.multiplier * bal.balance, 0)
  const assetIdToPrice: Map<string, [number, number]> = new Map<string, [number, number]>()
  collateral.forEach(item => assetIdToPrice.set(item.id, [item.price, item.haircut]))
  const uplUsd = positions.reduce((acc, psn) => {
    if (psn.settlementInfo === undefined) {
      console.warn('Got undefined settlement info')
      console.warn(psn)
      return acc + 0
    } else {
      const settlementInfo = assetIdToPrice.get(psn.settlementInfo.settlementCurrencyId)
      if (settlementInfo === undefined) {
        console.warn('Got undefined settlement info')
        console.warn(psn)
        return acc + 0
      } else {
        const [settlementPx, settlementHc] = settlementInfo
        return acc + (psn.settlementInfo.unrealizedPnl * settlementPx * settlementHc)
      }
    }
  }, 0)
  const marginReq = positions.reduce(
    (acc, pos) => {
      const quoteInfo = assetIdToPrice.get(pos.quoteCurrencyId)
      if (quoteInfo === undefined) {
        console.warn('Got undefined quote info')
        console.warn(pos)
        return acc + 0
      } else {
        const quotePx = quoteInfo[0]
        const priceUsd = pos.price * quotePx
        const psnWithRiskOffset = pos.quantity + (pos.riskOffsetInfo?.riskOffsetAmount ?? 0)
        return acc + priceUsd * Math.abs(psnWithRiskOffset) * pos.multiplier * pos.marginRequirementCoefficient
      }
    }, 0)
  return (equity + uplUsd) / marginReq
}

export function getStoneXHealthScore (collateral: readonly CollateralItem[], positions: readonly PositionItem[]): number {
  const equity = collateral.reduce((acc, bal) => acc + bal.price * bal.haircut * bal.multiplier * bal.balance, 0)
  const marginReq = positions.reduce((acc, pos) => acc + pos.price * pos.quantity * pos.multiplier * pos.marginRequirementCoefficient, 0)
  return equity / marginReq
}
