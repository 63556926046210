// Risk API
export const RISK_COMPONENTS_SLUG = 'components'
export const DERIBIT_RISK_SLUG = 'deribit-risk-config'

// Config API
export const COUNTERPARTIES_SLUG = 'counterparties'
export const ACCOUNTS_SLUG = 'accounts'

// Auth API
export const IS_AUTHENTICATED_SLUG = 'isAuthenticated'

// Market Data API
export const BETAS_SLUG = 'betas'
