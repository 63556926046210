import type { PositionType } from './types'

export function getPositionTypeForTicker (ticker: string): PositionType {
  if (ticker.endsWith('-C') || ticker.endsWith('-P')) {
    return 'option'
  } else if (ticker.endsWith('-PERPETUAL')) {
    return 'perpetual'
  } else {
    return 'future'
  }
}

export function getCurrencyPairForTicker (ticker: string): string {
  if (ticker.startsWith('ETH-')) {
    return 'eth_usd'
  } else if (ticker.startsWith('BTC-')) {
    return 'btc_usd'
  } else {
    const upperIndex = ticker.split('-', 2)[0]
    return upperIndex.toLowerCase()
  }
}

export function getSettlementCurrencyForTicker (ticker: string): string {
  if (ticker.startsWith('ETH-')) {
    return 'ETH'
  } else if (ticker.startsWith('BTC-')) {
    return 'BTC'
  } else {
    const upperIndex = ticker.split('-', 2)[0]
    return upperIndex.split('_', 2)[1].toUpperCase()
  }
}

export function getBaseCurrencyForTicker (ticker: string): string {
  if (ticker.startsWith('ETH-')) {
    return 'ETH'
  } else if (ticker.startsWith('BTC-')) {
    return 'BTC'
  } else {
    const upperIndex = ticker.split('-', 2)[0]
    return upperIndex.split('_', 2)[0].toLowerCase()
  }
}

function isNumericChar (char: string): boolean { return /\d/.test(char) }

function getMonthNumberFromString (monthDescription: string): number {
  switch (monthDescription) {
    case 'JAN': return 0
    case 'FEB': return 1
    case 'MAR': return 2
    case 'APR': return 3
    case 'MAY': return 4
    case 'JUN': return 5
    case 'JUL': return 6
    case 'AUG': return 7
    case 'SEP': return 8
    case 'OCT': return 9
    case 'NOV': return 10
    case 'DEC': return 11
    default: return 0
  }
}

export function getExpiryTimestampMsForTicker (ticker: string): number {
  const expiryDescription = ticker.split('-', 3)[1]
  const isTwoDigitDay = isNumericChar(expiryDescription.charAt(1))
  const day = isTwoDigitDay ? parseInt(expiryDescription.slice(0, 2)) : parseInt(expiryDescription.charAt(0))
  const monthDescription = isTwoDigitDay ? expiryDescription.slice(2, 5) : expiryDescription.slice(1, 4)
  const yearLastTwoDigits = isTwoDigitDay ? parseInt(expiryDescription.slice(5)) : parseInt(expiryDescription.slice(4))
  const month = getMonthNumberFromString(monthDescription)
  const year = 2000 + yearLastTwoDigits
  const timestampMs = Date.UTC(year, month, day, 8, 0, 0, 0)
  return timestampMs
}

export function getStrikeForTicker (ticker: string): number {
  const strikeDesc = ticker.split('-', 4)[2]
  const strikeStr = strikeDesc.replace('d', '.')
  return parseFloat(strikeStr)
}

export function getPriceCurrencyDisplayForTicker (ticker: string): string {
  const positionType = getPositionTypeForTicker(ticker)
  if (positionType === 'option') {
    if (ticker.startsWith('BTC-')) {
      return 'BTC'
    } else if (ticker.startsWith('ETH-')) {
      return 'ETH'
    } else {
      return 'USDC'
    }
  } else {
    if (ticker.startsWith('BTC-') || ticker.startsWith('ETH-')) {
      return 'USD'
    } else {
      return 'USDC'
    }
  }
}
