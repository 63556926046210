export interface CollateralRow {
  id: string
  ticker: string
  balance: number
  price: number
  haircut: number
  priceDecimals: number
  quantityDecimals: number
  marketValue: number
}

export interface DummyCollateralObject {
  balance: number
  price: number
}

export interface PositionRow {
  id: string
  ticker: string
  quantity: number
  price: number
  priceCurrency: string
  priceDecimals: number
  quantityDecimals: number
  marketValue: number
  annualizedFundingRatePct?: number
  delta?: number
  impliedVolatilityPct?: number
}

export interface DummyPositionObject {
  quantity: number
  price: number
  iv: number
}

export type PositionType = 'option' | 'future' | 'perpetual'

export enum InputType {
  FLOAT,
  PERCENTAGE
}

export interface ShockEffect {
  currencyId: string
  priceFactor: number
}
