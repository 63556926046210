import React, { useCallback, useEffect } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { Modal, type ColumnDefinition } from '@r40cap/ui'

import HealthScoreDisplay from './HealthScoreDisplay'
import CollateralView from './CollateralView'
import PositionsView from './PositionsView'
import { riskApi } from '../../sdk/reducers'
import type { CollateralItem, PositionItem } from '../../sdk/types'
import type { ShockEffect } from '../types'
import type { CollateralRow, InputType, PositionRow } from '../../types'
import ShockModalContent from '../modals/ShockModal'
import { useEventSystem } from '../../hooks/useEventSystem'
import { REFRESH, RESET } from '../../constants/events'

function AccountBody (props: {
  setHasEdited: (hasEdited: boolean) => void
  getHealthScore: (collateral: readonly CollateralItem[], positions: readonly PositionItem[]) => number
  getFrozenValue: (positions: readonly PositionItem[]) => number
  accountId: string
  collateralColumns: Array<ColumnDefinition<CollateralRow, InputType, CollateralItem>>
  positionColumns: Array<ColumnDefinition<PositionRow, InputType, PositionItem>>
  shockModalOpen: boolean
  setShockModalOpen: (value: boolean) => void
  showMmr?: boolean
  showTotalGrossNotional?: boolean
  showTotalMv?: boolean
  showDiscoutedMv?: boolean
}): React.JSX.Element {
  const {
    setHasEdited,
    getHealthScore,
    accountId,
    collateralColumns,
    positionColumns,
    shockModalOpen,
    setShockModalOpen,
    showTotalMv,
    showDiscoutedMv,
    showMmr,
    showTotalGrossNotional,
    getFrozenValue
  } = props
  const { subscribe } = useEventSystem()
  const [healthScore, setHealthScore] = React.useState<number | null>(null)
  const [editedCollateral, setEditedCollateral] = React.useState<readonly CollateralItem[]>([])
  const [editedPositions, setEditedPositions] = React.useState<readonly PositionItem[]>([])

  const { data, isFetching, refetch } = riskApi.useGetRiskComponentsQuery({ accountId })

  const handleRefresh = useCallback(() => {
    refetch().catch((err) => { console.error(err) })
  }, [refetch])

  useEffect(() => {
    const unsubscribe = subscribe(REFRESH, handleRefresh)
    return () => {
      unsubscribe()
    }
  }, [subscribe, handleRefresh])

  const handleReset = useCallback(() => {
    if (data === null || data === undefined) {
      setEditedCollateral([])
      setEditedPositions([])
      setHasEdited(false)
    } else {
      setEditedCollateral(data.data.collateral)
      setEditedPositions(data.data.positions)
      setHasEdited(false)
    }
  }, [setHasEdited, data])

  useEffect(() => {
    const unsubscribe = subscribe(RESET, handleReset)
    return () => {
      unsubscribe()
    }
  }, [subscribe, handleReset])

  useEffect(() => {
    if (data === null || data === undefined) {
      setHealthScore(null)
    } else {
      setHealthScore(getHealthScore(editedCollateral, editedPositions))
    }
  }, [editedCollateral, editedPositions, data, getHealthScore])

  useEffect(() => {
    if (data === null || data === undefined) {
      setEditedCollateral([])
      setEditedPositions([])
      setHasEdited(false)
    } else {
      setEditedCollateral(data.data.collateral)
      setEditedPositions(data.data.positions)
      setHasEdited(false)
    }
  }, [data, setHasEdited])

  function handleBalanceEdit (property: keyof CollateralItem, value: any, editedCurrencyId: string): void {
    if (property === 'price') {
      const itemToUpdate = editedCollateral.find(item => item.id === editedCurrencyId)
      if (itemToUpdate === undefined) {
        return
      }
      const pxChangeFactor = value / itemToUpdate.price
      const updatedPositionsData = editedPositions.map(item => {
        if (item.baseCurrencyId === editedCurrencyId) {
          const newPrice = item.price * pxChangeFactor * item.delta
          if (item.settlementInfo === undefined) {
            return { ...item, price: newPrice }
          } else {
            const pxDelta = newPrice - item.price
            const uplDelta = pxDelta * item.multiplier * item.quantity
            return {
              ...item,
              price: newPrice,
              settlementInfo: {
                settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
                unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
              }
            }
          }
        } else if (item.quoteCurrencyId === editedCurrencyId) {
          const newPrice = 1 / (1 / item.price * pxChangeFactor * item.delta)
          if (item.settlementInfo === undefined) {
            return { ...item, price: newPrice }
          } else {
            const pxDelta = newPrice - item.price
            const uplDelta = pxDelta * item.multiplier * item.quantity
            return {
              ...item,
              price: newPrice,
              settlementInfo: {
                settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
                unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
              }
            }
          }
        } else {
          return item
        }
      })
      setEditedPositions(updatedPositionsData)
    } else if (property === 'balance') {
      const totalUsedAsRiskOffset = editedPositions.reduce((acc, item) => {
        if (item.riskOffsetInfo === undefined || item.riskOffsetInfo.riskOffsetCurrencyId !== editedCurrencyId) {
          return acc
        } else {
          return acc + item.riskOffsetInfo.riskOffsetAmount
        }
      }, 0)
      if (totalUsedAsRiskOffset > value) {
        const reductionFactor = value / totalUsedAsRiskOffset
        const updatedPositionsData = editedPositions.map(item => {
          if (item.riskOffsetInfo?.riskOffsetCurrencyId === editedCurrencyId) {
            return {
              ...item,
              riskOffsetInfo: {
                riskOffsetCurrencyId: item.riskOffsetInfo.riskOffsetCurrencyId,
                riskOffsetAmount: item.riskOffsetInfo.riskOffsetAmount * reductionFactor
              }
            }
          } else {
            return item
          }
        })
        setEditedPositions(updatedPositionsData)
      }
    }
    const updatedCollateralData = editedCollateral.map(item =>
      item.id === editedCurrencyId ? { ...item, [property]: value } : item
    )
    setEditedCollateral(updatedCollateralData)
    setHasEdited(true)
  }

  function handlePositionsEdit (property: keyof PositionItem, value: any, editedPositionId: string): void {
    if (property === 'price') {
      const itemToUpdate = editedPositions.find(item => item.id === editedPositionId)
      if (itemToUpdate === undefined) {
        return
      }
      const pxChangeFactor = (value / itemToUpdate.price) / itemToUpdate.delta
      const updatedCollateralData = editedCollateral.map(item => {
        if (item.id === itemToUpdate.baseCurrencyId) {
          return { ...item, price: item.price * pxChangeFactor }
        } else {
          return item
        }
      })
      setEditedCollateral(updatedCollateralData)
      const updatedData = editedPositions.map(item => {
        if (editedPositionId === item.id) {
          if (item.settlementInfo === undefined) {
            return { ...item, price: value }
          } else {
            const pxDelta = (value as number) - item.price
            const uplDelta = pxDelta * item.multiplier * item.quantity
            return {
              ...item,
              price: value,
              settlementInfo: {
                settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
                unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
              }
            }
          }
        } else {
          return item
        }
      })
      setEditedPositions(updatedData)
    } else {
      const updatedData = editedPositions.map(item =>
        editedPositionId === item.id ? { ...item, [property]: value } : item
      )
      setEditedPositions(updatedData)
    }
    setHasEdited(true)
  }

  function handlePriceShock (effects: ShockEffect[]): void {
    const shockMap = new Map(effects.map(effect => [effect.currencyId, effect.priceFactor]))
    const updatedCollateralData = editedCollateral.map(item => {
      const shockFactor = shockMap.get(item.id)
      if (shockFactor === undefined) {
        return item
      } else {
        return { ...item, price: item.price * shockFactor }
      }
    })
    setEditedCollateral(updatedCollateralData)
    const updatedPositionsData = editedPositions.map(item => {
      const baseShockFactor = shockMap.get(item.baseCurrencyId) ?? 1
      const quoteShockFactor = shockMap.get(item.quoteCurrencyId) ?? 1
      const netShockFactor = baseShockFactor / quoteShockFactor
      const newPrice = item.price * netShockFactor
      if (item.settlementInfo === undefined) {
        return { ...item, price: newPrice }
      } else {
        const pxDelta = newPrice - item.price
        const uplDelta = pxDelta * item.multiplier * item.quantity
        return {
          ...item,
          price: newPrice,
          settlementInfo: {
            settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
            unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
          }
        }
      }
    })
    setEditedPositions(updatedPositionsData)
    setHasEdited(true)
  }

  return (
    <>
      <Stack
        sx={{ height: '100%', width: '100%', alignItems: 'center' }}
        spacing={1}
      >
        <Box sx={{ width: '30%', height: '20vh', minWidth: '200px' }}>
          <HealthScoreDisplay
            score={healthScore}
            platformRiskMetric={data?.data.platformRiskMetric}
            isFetching={isFetching}
          />
        </Box>
        <Grid
          container
          overflow={'scroll'}
          sx={{ height: 'calc(80vh - 7% - 90px - 10px)' }}
        >
          <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
            <CollateralView
              isFetching={isFetching}
              data={editedCollateral}
              columns={collateralColumns}
              editSubmission={handleBalanceEdit}
              showTotalMv={showTotalMv}
              showDiscoutedMv={showDiscoutedMv}
              frozenValue={getFrozenValue(editedPositions)}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
            <PositionsView
              isFetching={isFetching}
              data={editedPositions}
              columns={positionColumns}
              editSubmission={handlePositionsEdit}
              priceMap={new Map(editedCollateral.map(item => [item.id, item.price]))}
              showMmr={showMmr}
              showTotalGrossNotional={showTotalGrossNotional}
            />
          </Grid>
        </Grid>
      </Stack>
      <Modal
        handleClose={() => setShockModalOpen(false)}
        open={shockModalOpen}
      >
        <ShockModalContent
          processShock={handlePriceShock}
          closeModal={() => setShockModalOpen(false)} 
        />
      </Modal>
    </>
  )
}

export default AccountBody
