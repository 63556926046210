import jStat from 'jstat'

import {
  type DeribitCrossPortfolioMarginCurrencyParams,
  type DeribitCrossPortfolioMarginConfig,
  type DeribitCrossPortfolioMarginCurrencyPairParams,
  type DeribitCrossPortfolioMarginTickerDetails
} from '../../sdk/types'
import {
  getCurrencyPairForTicker,
  getSettlementCurrencyForTicker,
  getPositionTypeForTicker,
  getBaseCurrencyForTicker,
  getExpiryTimestampMsForTicker,
  getStrikeForTicker
} from './tickerUtils'
import { type PositionType } from './types'

export const MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365

function getDeltaShockForCurrencyPair (
  positions: Array<[string, number]>,
  maxDeltaShock: number,
  pairPrice: number,
  stableIndex: number,
  deltaTotalLiqShockThreshold: number,
  increment: number,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const delta1 = positions.map(([ticker, quantity]) => {
    const details = tickerToDetails[ticker]
    const delta = details.delta
    const positionType = getPositionTypeForTicker(ticker)
    if (positionType === 'option' && quantity > 0) {
      return (delta ?? 0) * quantity
    } else {
      return 0
    }
  }).reduce((acc, val) => acc + val, 0)
  const deltaFutures = positions.map(([ticker, quantity]) => {
    const positionType = getPositionTypeForTicker(ticker)
    if (positionType === 'option') {
      return 0
    } else {
      return quantity
    }
  }).reduce((acc, val) => acc + val, 0)
  const deltaShort = positions.map(([ticker, quantity]) => {
    const details = tickerToDetails[ticker]
    const delta = details.delta
    const positionType = getPositionTypeForTicker(ticker)
    if (positionType === 'option' && quantity < 0) {
      return (delta ?? 0) * quantity
    } else {
      return 0
    }
  }).reduce((acc, val) => acc + val, 0)
  const delta2 = deltaFutures + deltaShort
  const deltaForShock = delta2 < 0
    ? Math.abs(Math.min(Math.max(delta1 + delta2, delta2), 0))
    : Math.abs(Math.max(Math.min(delta1 + delta2, delta2), 0))
  const minMaxResult = Math.min(
    Math.max(deltaForShock * pairPrice - deltaTotalLiqShockThreshold, 0) * deltaForShock * increment,
    maxDeltaShock * pairPrice * deltaForShock
  )
  return minMaxResult * stableIndex
}

function getDeltaShockMarginRequirement (
  currencyPairToParams: Record<string, DeribitCrossPortfolioMarginCurrencyPairParams>,
  indexPrice: Record<string, number>,
  positions: Record<string, number>,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const currencyPairToPositions: Record<string, Array<[string, number]>> = {}
  Object.entries(positions).forEach(([ticker, quantity]) => {
    const currencyPair = getCurrencyPairForTicker(ticker)
    if (currencyPair in currencyPairToPositions) {
      currencyPairToPositions[currencyPair].push([ticker, quantity])
    } else {
      currencyPairToPositions[currencyPair] = [[ticker, quantity]]
    }
  })
  const totalDeltaShock = Object.entries(currencyPairToPositions).map(([currencyPair, positions]) => {
    if (positions.length === 0) {
      return 0
    }
    const settlementCurrency = getSettlementCurrencyForTicker(positions[0][0])
    const pairPrice = indexPrice[currencyPair]
    const stableIndex = settlementCurrency === 'ETH' || settlementCurrency === 'BTC'
      ? 1
      : settlementCurrency === 'USDC'
        ? 1
        : pairPrice
    const pairParams = currencyPairToParams[currencyPair]
    return getDeltaShockForCurrencyPair(
      positions,
      pairParams.maxDeltaShock,
      pairPrice,
      stableIndex,
      pairParams.deltaTotalLiqShockThreshold,
      pairParams.increment,
      tickerToDetails
    )
  }).reduce((acc, val) => acc + val, 0)
  return totalDeltaShock
}

function getNetNotionalDeltaForPosition (
  quantity: number,
  delta: number,
  markPrice: number,
  positionType: PositionType,
  settlementCurrency: string,
  indexPrice: number
): number {
  const netDelta = (settlementCurrency === 'ETH' || settlementCurrency === 'BTC') && positionType === 'option'
    ? quantity * (delta - markPrice)
    : quantity * delta
  return netDelta * indexPrice
}

function getMinimumRollShock (
  positions: Array<[string, number]>,
  minAnnualizedMove: number,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const expiryToPositions: Record<number, Array<[string, number]>> = {}
  positions.forEach(([ticker, quantity]) => {
    const expiryMs = getExpiryTimestampMsForTicker(ticker)
    if (expiryMs in expiryToPositions) {
      expiryToPositions[expiryMs].push([ticker, quantity])
    } else {
      expiryToPositions[expiryMs] = [[ticker, quantity]]
    }
  })
  const totalMinimumRollShock = Object.entries(expiryToPositions).map(([expiryMs, positions]) => {
    const totalNetNotionalDelta = positions.map(([ticker, quantity]) => {
      const settlementCurrency = getSettlementCurrencyForTicker(ticker)
      const details = tickerToDetails[ticker]
      return getNetNotionalDeltaForPosition(
        quantity,
        details.delta ?? 1,
        details.markPrice,
        getPositionTypeForTicker(ticker),
        settlementCurrency,
        details.indexPrice
      )
    }).reduce((acc, val) => acc + val, 0)
    return minAnnualizedMove * Math.abs(totalNetNotionalDelta)
  }).reduce((acc, val) => acc + val, 0)
  return totalMinimumRollShock
}

function getAnnualizedRollShock (
  positions: Array<[string, number]>,
  minAnnualizedMove: number,
  nowMs: number,
  annualizedMoveRisk: number,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const expiryToPositions: Record<number, Array<[string, number]>> = {}
  positions.forEach(([ticker, quantity]) => {
    const positionType = getPositionTypeForTicker(ticker)
    const expiryMs = positionType === 'perpetual'
      ? nowMs
      : getExpiryTimestampMsForTicker(ticker)
    if (expiryMs in expiryToPositions) {
      expiryToPositions[expiryMs].push([ticker, quantity])
    } else {
      expiryToPositions[expiryMs] = [[ticker, quantity]]
    }
  })
  const totalAnnualizedRollShock = Object.entries(expiryToPositions).map(([expiryMs, positions]) => {
    const totalNetNotionalDelta = positions.map(([ticker, quantity]) => {
      const settlementCurrency = getSettlementCurrencyForTicker(ticker)
      const details = tickerToDetails[ticker]
      const netNotionalDelta = getNetNotionalDeltaForPosition(
        quantity,
        details.delta ?? 1,
        details.markPrice,
        getPositionTypeForTicker(ticker),
        settlementCurrency,
        details.indexPrice
      )
      return netNotionalDelta
    }).reduce((acc, val) => acc + val, 0)
    const yearsToExpiration = (parseInt(expiryMs) - nowMs) / MS_PER_YEAR
    const leftSide = Math.max(Math.exp(annualizedMoveRisk * yearsToExpiration) - 1, minAnnualizedMove)
    return leftSide * totalNetNotionalDelta
  }).reduce((acc, val) => acc + val, 0)
  return totalAnnualizedRollShock
}

function getRollShockForBaseCurrency (
  positions: Array<[string, number]>,
  nowMs: number,
  annualizedMoveRisk: number,
  minAnnualizedMove: number,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const minimumRollShock = getMinimumRollShock(positions, minAnnualizedMove, tickerToDetails)
  const annualizedRollShock = getAnnualizedRollShock(
    positions,
    minAnnualizedMove,
    nowMs,
    annualizedMoveRisk,
    tickerToDetails
  )
  return Math.max(minimumRollShock, Math.abs(annualizedRollShock))
}

function getRollShockMarginRequirement (
  currencyToParams: Record<string, DeribitCrossPortfolioMarginCurrencyParams>,
  positions: Record<string, number>,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>,
  nowMs: number
): number {
  const baseToPositions: Record<string, Array<[string, number]>> = {}
  Object.entries(positions).forEach(([ticker, quantity]) => {
    const baseCurrency = getBaseCurrencyForTicker(ticker).toLowerCase()
    if (baseCurrency in baseToPositions) {
      baseToPositions[baseCurrency].push([ticker, quantity])
    } else {
      baseToPositions[baseCurrency] = [[ticker, quantity]]
    }
  })
  const totalRollShock = Object.entries(baseToPositions).map(([baseCurrency, positions]) => {
    if (positions.length === 0) {
      return 0
    }
    const baseParams = currencyToParams[baseCurrency]
    return getRollShockForBaseCurrency(
      positions,
      nowMs,
      baseParams.annualisedMoveRisk,
      baseParams.minAnnualisedMove,
      tickerToDetails
    )
  }).reduce((acc, val) => acc + val, 0)
  return totalRollShock
}

type VolShock = 'down' | 'same' | 'up'

const MAIN_MATRIX_BUCKETS = [-4, -3, -2, -1, 0, 1, 2, 3, 4]
const MAIN_MATRIX_VOL_SHOCKS: VolShock[] = ['down', 'same', 'up']
const EXTENDED_MATRIX_PRICE_MOVES = [-0.66, -0.33, 0.5, 1.0, 2.0, 3.0, 4.0, 5.0]

function getPriceMoveForBucket (
  priceRange: number,
  bucket: number
): number {
  return (priceRange / 4) * bucket
}

function getNewIv (
  iv: number,
  volShock: VolShock,
  shortTermVegaPower: number,
  longTermVegaPower: number,
  volatitlityRangeDown: number,
  volatitlityRangeUp: number,
  minVolatilityForShockUp: number,
  nowMs: number,
  expiryMs: number
): number {
  if (volShock === 'same') {
    return iv
  }
  const daysToExpiration = (expiryMs - nowMs) / (MS_PER_YEAR / 365)
  const vegaPower = daysToExpiration < 30 ? shortTermVegaPower : longTermVegaPower
  const constVal = volShock === 'up' ? minVolatilityForShockUp : 0
  const varVal = volShock === 'up'
    ? iv * (1 + ((30 / daysToExpiration) ** vegaPower) * volatitlityRangeUp)
    : iv * (1 - ((30 / daysToExpiration) ** vegaPower) * volatitlityRangeDown)
  return Math.max(constVal, varVal)
}

function getDs (
  strike: number,
  underlyingPrice: number,
  iv: number,
  yearsToExpiration: number
): [number, number] {
  const d1 = (Math.log(underlyingPrice / strike) + (0.5 * Math.pow(iv, 2)) * yearsToExpiration) / (iv * Math.sqrt(yearsToExpiration))
  const d2 = d1 - iv * Math.sqrt(yearsToExpiration)
  return [d1, d2]
}

function normCdf (x: number): number {
  return jStat.normal.cdf(x, 0, 1)
}

export function getLinearPutOptionPriceAndDelta (
  strike: number,
  underlyingPrice: number,
  iv: number,
  yearsToExpiration: number
): [number, number] {
  if (iv === 0) {
    return [
      underlyingPrice < strike ? strike - underlyingPrice : 0,
      underlyingPrice < strike ? -1 : 0
    ]
  }
  const [d1, d2] = getDs(strike, underlyingPrice, iv, yearsToExpiration)
  return [strike * normCdf(-1 * d2) - underlyingPrice * normCdf(-1 * d1), normCdf(d1) - 1]
}

export function getLinearCallOptionPriceAndDelta (
  strike: number,
  underlyingPrice: number,
  iv: number,
  yearsToExpiration: number
): [number, number] {
  if (iv === 0) {
    return [
      underlyingPrice > strike ? underlyingPrice - strike : 0,
      underlyingPrice > strike ? 1 : 0
    ]
  }
  const [d1, d2] = getDs(strike, underlyingPrice, iv, yearsToExpiration)
  return [underlyingPrice * normCdf(d1) - strike * normCdf(d2), normCdf(d1)]
}

export function getInversePutOptionPriceAndDelta (
  strike: number,
  underlyingPrice: number,
  iv: number,
  yearsToExpiration: number
): [number, number] {
  const [linearPrice, delta] = getLinearPutOptionPriceAndDelta(strike, underlyingPrice, iv, yearsToExpiration)
  return [linearPrice / underlyingPrice, delta]
}

export function getInverseCallOptionPriceAndDelta (
  strike: number,
  underlyingPrice: number,
  iv: number,
  yearsToExpiration: number
): [number, number] {
  const [linearPrice, delta] = getLinearCallOptionPriceAndDelta(strike, underlyingPrice, iv, yearsToExpiration)
  return [linearPrice / underlyingPrice, delta]
}

function getOptionPerformanceForMainMatrixScenario (
  ticker: string,
  tickerDetails: DeribitCrossPortfolioMarginTickerDetails,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  bucket: number,
  volShock: VolShock,
  nowMs: number
): number {
  if (
    tickerDetails.underlyingPrice === null ||
    tickerDetails.underlyingPrice === undefined
  ) {
    console.error('No Underlying price for option %s', ticker)
    console.error(tickerDetails)
    return 0
  }
  if (
    tickerDetails.iv === null ||
    tickerDetails.iv === undefined
  ) {
    console.error('No IV for option %s', ticker)
    console.error(tickerDetails)
    return 0
  }
  const settlementCurrency = getSettlementCurrencyForTicker(ticker)
  const isInverse = settlementCurrency === 'BTC' || settlementCurrency === 'ETH'
  const priceMove = getPriceMoveForBucket(
    pairParams.priceRange,
    bucket
  )
  const newUnderlyingPrice = tickerDetails.underlyingPrice * (1 + priceMove)
  const expiryMs = getExpiryTimestampMsForTicker(ticker)
  const yearsToExpiration = (expiryMs - nowMs) / MS_PER_YEAR
  const newIv = getNewIv(
    tickerDetails.iv,
    volShock,
    pairParams.shortTermVegaPower,
    pairParams.longTermVegaPower,
    pairParams.volatilityRangeDown,
    pairParams.volatilityRangeUp,
    pairParams.minVolatilityForShockUp,
    nowMs,
    expiryMs
  )
  const strike = getStrikeForTicker(ticker)
  const isPut = ticker.endsWith('-P')
  const newPrice = isInverse
    ? isPut
      ? getInversePutOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
      : getInverseCallOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
    : isPut
      ? getLinearPutOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
      : getLinearCallOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
  const profitsInKind = (newPrice - tickerDetails.markPrice) * quantity
  const profitsInUsd = isInverse
    ? profitsInKind * tickerDetails.indexPrice * (newUnderlyingPrice / tickerDetails.underlyingPrice)
    : profitsInKind
  return profitsInUsd
}

function getFuturePerformanceForMainMatrixScenario (
  ticker: string,
  tickerDetails: DeribitCrossPortfolioMarginTickerDetails,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  bucket: number
): number {
  const settlementCurrency = getSettlementCurrencyForTicker(ticker)
  const isInverse = settlementCurrency === 'BTC' || settlementCurrency === 'ETH'
  const oldPrice = isInverse ? tickerDetails.indexPrice : tickerDetails.markPrice
  const priceMove = getPriceMoveForBucket(
    pairParams.priceRange,
    bucket
  )
  const newPrice = oldPrice * (1 + priceMove)
  return quantity * (newPrice - oldPrice)
}

function getPositionPerformanceForMainMatrixScenario (
  ticker: string,
  tickerDetails: DeribitCrossPortfolioMarginTickerDetails,
  positionType: PositionType,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  bucket: number,
  volShock: VolShock,
  nowMs: number
): number {
  return positionType === 'option'
    ? getOptionPerformanceForMainMatrixScenario(
      ticker,
      tickerDetails,
      quantity,
      pairParams,
      bucket,
      volShock,
      nowMs
    )
    : getFuturePerformanceForMainMatrixScenario(
      ticker,
      tickerDetails,
      quantity,
      pairParams,
      bucket
    )
}

function getBalancePerformanceForMainMatrixScenario (
  currency: string,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  pairPrice: number,
  bucket: number
): number {
  const priceMove = currency === 'usdc' || currency === 'usdt'
    ? 0
    : getPriceMoveForBucket(
      pairParams.priceRange,
      bucket
    )
  const resultingPrice = pairPrice * (1 + priceMove)
  return quantity * (resultingPrice - pairPrice)
}

function getPerformanceForMainMatrixScenario (
  positions: Array<[string, number]>,
  balances: Array<[string, number]>,
  currencyPairToParams: Record<string, DeribitCrossPortfolioMarginCurrencyPairParams>,
  indexPrice: Record<string, number>,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>,
  nowMs: number,
  bucket: number,
  volShock: VolShock
): number {
  const positionsPerformance = positions.map(([ticker, quantity]) => {
    const tickerDetails = tickerToDetails[ticker]
    const positionType = getPositionTypeForTicker(ticker)
    const currencyPair = getCurrencyPairForTicker(ticker)
    const pairParams = currencyPairToParams[currencyPair]
    return getPositionPerformanceForMainMatrixScenario(
      ticker,
      tickerDetails,
      positionType,
      quantity,
      pairParams,
      bucket,
      volShock,
      nowMs
    )
  }).reduce((acc, val) => acc + val, 0)
  const balancesPerformance = balances.map(([currency, quantity]) => {
    const pair = `${currency.toLowerCase()}_usd`
    const pairParams = currencyPairToParams[pair]
    const pairPrice = indexPrice[pair]
    return getBalancePerformanceForMainMatrixScenario(
      currency,
      quantity,
      pairParams,
      pairPrice,
      bucket
    )
  }).reduce((acc, val) => acc + val, 0)
  return positionsPerformance + balancesPerformance
}

function getSimulatedOptionPerformanceForExtendedMatrixScenario (
  ticker: string,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  tickerDetails: DeribitCrossPortfolioMarginTickerDetails,
  priceMove: number,
  nowMs: number
): number {
  if (
    tickerDetails.underlyingPrice === null ||
    tickerDetails.underlyingPrice === undefined
  ) {
    console.error('No Underlying price for option %s', ticker)
    console.error(tickerDetails)
    return 0
  }
  if (
    tickerDetails.iv === null ||
    tickerDetails.iv === undefined
  ) {
    console.error('No IV for option %s', ticker)
    console.error(tickerDetails)
    return 0
  }
  const settlementCurrency = getSettlementCurrencyForTicker(ticker)
  const newUnderlyingPrice = (1 + priceMove) * tickerDetails.underlyingPrice
  const expiryMs = getExpiryTimestampMsForTicker(ticker)
  const yearsToExpiration = (expiryMs - nowMs) / MS_PER_YEAR
  const newIv = getNewIv(
    tickerDetails.iv,
    'up',
    pairParams.shortTermVegaPower,
    pairParams.longTermVegaPower,
    pairParams.volatilityRangeDown,
    pairParams.volatilityRangeUp,
    pairParams.minVolatilityForShockUp,
    nowMs,
    expiryMs
  )
  const strike = getStrikeForTicker(ticker)
  const isPut = ticker.endsWith('-P')
  const isInverse = settlementCurrency === 'BTC' || settlementCurrency === 'ETH'
  const newPrice = isInverse
    ? isPut
      ? getInversePutOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
      : getInverseCallOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
    : isPut
      ? getLinearPutOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
      : getLinearCallOptionPriceAndDelta(strike, newUnderlyingPrice, newIv, yearsToExpiration)[0]
  const profitsInKind = (newPrice - tickerDetails.markPrice) * quantity
  const profitsInUsd = isInverse
    ? profitsInKind * tickerDetails.indexPrice * (newUnderlyingPrice / tickerDetails.underlyingPrice)
    : profitsInKind
  return profitsInUsd
}

function getSimulatedFuturePerformanceForExtendedMatrixScenario (
  ticker: string,
  quantity: number,
  tickerDetails: DeribitCrossPortfolioMarginTickerDetails,
  priceMove: number
): number {
  const settlementCurrency = getSettlementCurrencyForTicker(ticker)
  const oldPrice = settlementCurrency === 'BTC' || settlementCurrency === 'ETH'
    ? tickerDetails.indexPrice
    : tickerDetails.markPrice
  const newPrice = oldPrice * (1 + priceMove)
  return quantity * (newPrice - oldPrice)
}

function applyExtendedTableAdjustements (
  simulatedProfitsUsd: number,
  priceMove: number,
  priceRange: number,
  extendedTableFactor: number
): number {
  const marginMultiplier = extendedTableFactor * (priceRange / Math.abs(priceMove))
  const adjustedSimulatedPnl = simulatedProfitsUsd * marginMultiplier
  return adjustedSimulatedPnl
}

function getAdjustedPositionPerformanceForExtendedMatrixScenario (
  ticker: string,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  tickerDetails: DeribitCrossPortfolioMarginTickerDetails,
  priceMove: number,
  nowMs: number
): number {
  const positionType = getPositionTypeForTicker(ticker)
  const simulatedProfitsUsd = positionType === 'option'
    ? getSimulatedOptionPerformanceForExtendedMatrixScenario(
      ticker,
      quantity,
      pairParams,
      tickerDetails,
      priceMove,
      nowMs
    )
    : getSimulatedFuturePerformanceForExtendedMatrixScenario(
      ticker,
      quantity,
      tickerDetails,
      priceMove
    )
  const adjustedResult = applyExtendedTableAdjustements(
    simulatedProfitsUsd,
    priceMove,
    pairParams.priceRange,
    pairParams.extendedTableFactor
  )
  return adjustedResult
}

function getAdjustedBalancePerformanceForExtendedMatrixScenario (
  currency: string,
  quantity: number,
  pairParams: DeribitCrossPortfolioMarginCurrencyPairParams,
  pairPrice: number,
  priceMove: number
): number {
  const resultingPrice = currency === 'usdc' || currency === 'usdt'
    ? pairPrice
    : pairPrice * (1 + priceMove)
  const simulatedProfitsUsd = quantity * (resultingPrice - pairPrice)
  const adjustedResult = applyExtendedTableAdjustements(
    simulatedProfitsUsd,
    priceMove,
    pairParams.priceRange,
    pairParams.extendedTableFactor
  )
  return adjustedResult
}

function sign (x: number): number { return x < 0 ? -1 : 1 }

function applyDampener (
  adjustedPerformance: number,
  priceMove: number,
  priceRange: number,
  extendedDampener: number
): number {
  const amountToBeDampened = Math.min(
    (Math.max(Math.abs(priceMove) / priceRange, 1) - 1) * extendedDampener,
    Math.abs(adjustedPerformance)
  )
  return (Math.abs(adjustedPerformance) - amountToBeDampened) * sign(adjustedPerformance)
}

function getAdjustedPerformanceForExtendedMatrixScenario (
  baseCurrency: string,
  positions: Array<[string, number]>,
  balances: Array<[string, number]>,
  currencyPairToParams: Record<string, DeribitCrossPortfolioMarginCurrencyPairParams>,
  indexPrice: Record<string, number>,
  baseCurrencyParams: DeribitCrossPortfolioMarginCurrencyParams,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>,
  nowMs: number,
  priceMove: number
): number {
  const positionsAdjustedPerformance = positions.map(([ticker, quantity]) => {
    const pair = getCurrencyPairForTicker(ticker)
    const pairParams = currencyPairToParams[pair]
    const tickerDetails = tickerToDetails[ticker]
    return getAdjustedPositionPerformanceForExtendedMatrixScenario(
      ticker,
      quantity,
      pairParams,
      tickerDetails,
      priceMove,
      nowMs
    )
  }).reduce((acc, val) => acc + val, 0)
  const balancesAdjustedPerformance = balances.map(([currency, quantity]) => {
    const pair = `${currency.toLowerCase()}_usd`
    const pairParams = currencyPairToParams[pair]
    const pairPrice = indexPrice[pair]
    return getAdjustedBalancePerformanceForExtendedMatrixScenario(
      currency,
      quantity,
      pairParams,
      pairPrice,
      priceMove
    )
  }).reduce((acc, val) => acc + val, 0)
  const preDampenerPerformance = positionsAdjustedPerformance + balancesAdjustedPerformance
  const basePair = baseCurrency === 'usdc' || baseCurrency === 'usdt' || baseCurrency === 'eth' || baseCurrency === 'btc'
    ? `${baseCurrency}_usd`
    : `${baseCurrency}_usdc`
  const basePairParams = currencyPairToParams[basePair]
  const postDampenerPerformance = applyDampener(
    preDampenerPerformance,
    priceMove,
    basePairParams.priceRange,
    baseCurrencyParams.extendedDampener
  )
  return postDampenerPerformance
}

function getWorstCasePerformanceForBaseCurrency (
  baseCurrency: string,
  positions: Array<[string, number]>,
  balances: Array<[string, number]>,
  currencyPairToParams: Record<string, DeribitCrossPortfolioMarginCurrencyPairParams>,
  indexPrice: Record<string, number>,
  baseCurrencyParams: DeribitCrossPortfolioMarginCurrencyParams,
  nowMs: number,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const mainMatrixBiggestLoss = MAIN_MATRIX_BUCKETS.map((bucket) => {
    return MAIN_MATRIX_VOL_SHOCKS.map((volShock) => {
      return getPerformanceForMainMatrixScenario(
        positions,
        balances,
        currencyPairToParams,
        indexPrice,
        tickerToDetails,
        nowMs,
        bucket,
        volShock
      )
    }).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE)
  }).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE)
  const extendedMatrixBiggestLoss = EXTENDED_MATRIX_PRICE_MOVES.map((priceMove) => {
    return getAdjustedPerformanceForExtendedMatrixScenario(
      baseCurrency,
      positions,
      balances,
      currencyPairToParams,
      indexPrice,
      baseCurrencyParams,
      tickerToDetails,
      nowMs,
      priceMove
    )
  }).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE)
  return Math.min(mainMatrixBiggestLoss, extendedMatrixBiggestLoss)
}

function getWorstCasePerformance (
  positions: Record<string, number>,
  balances: Record<string, number>,
  currencyPairToParams: Record<string, DeribitCrossPortfolioMarginCurrencyPairParams>,
  indexPrice: Record<string, number>,
  currencyToParams: Record<string, DeribitCrossPortfolioMarginCurrencyParams>,
  nowMs: number,
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
): number {
  const baseToPositions: Record<string, Array<[string, number]>> = {}
  Object.entries(positions).forEach(([ticker, quantity]) => {
    const baseCurrency = getBaseCurrencyForTicker(ticker).toLowerCase()
    if (baseCurrency in baseToPositions) {
      baseToPositions[baseCurrency].push([ticker, quantity])
    } else {
      baseToPositions[baseCurrency] = [[ticker, quantity]]
    }
  })
  const baseToBalances: Record<string, Array<[string, number]>> = {}
  Object.entries(balances).forEach(([currency, quantity]) => {
    if (currency in baseToBalances) {
      baseToBalances[currency].push([currency, quantity])
    } else {
      baseToBalances[currency] = [[currency, quantity]]
    }
  })
  const allRelevantBases = new Set([
    ...Object.keys(baseToPositions),
    ...Object.keys(baseToBalances)
  ])
  const totalWorstCasePerformance = Array.from(allRelevantBases).map(baseCurrency => {
    const basePositions = baseCurrency in baseToPositions ? baseToPositions[baseCurrency] : []
    const baseBalances = baseCurrency in baseToBalances ? baseToBalances[baseCurrency] : []
    const baseCurrencyParams = currencyToParams[baseCurrency]
    return getWorstCasePerformanceForBaseCurrency(
      baseCurrency,
      basePositions,
      baseBalances,
      currencyPairToParams,
      indexPrice,
      baseCurrencyParams,
      nowMs,
      tickerToDetails
    )
  }).reduce((acc, val) => acc + val, 0)
  return totalWorstCasePerformance
}

function getRiskMatrixMarginRequirement (config: DeribitCrossPortfolioMarginConfig): number {
  const deltaShockMarginRequirement = getDeltaShockMarginRequirement(
    config.modelParams.currencyPair,
    config.indexPrice,
    config.portfolio.position,
    config.ticker
  )
  console.log('Delta Shock: %s', deltaShockMarginRequirement)
  const rollShockMarginRequirement = getRollShockMarginRequirement(
    config.modelParams.currency,
    config.portfolio.position,
    config.ticker,
    config.modelParams.general.timestamp
  )
  console.log('Roll Shock: %s', rollShockMarginRequirement)
  const worstCasePerformance = getWorstCasePerformance(
    config.portfolio.position,
    config.portfolio.currency,
    config.modelParams.currencyPair,
    config.indexPrice,
    config.modelParams.currency,
    config.modelParams.general.timestamp,
    config.ticker
  )
  console.log('Worst Case Performance: %s', worstCasePerformance)
  return deltaShockMarginRequirement + rollShockMarginRequirement - worstCasePerformance
}

function getOpenOrdersMarginRequirement (): number {
  return 0 // TODO: Implement this
}

export function getDeribitMaintenanceMarginRequirement (config: DeribitCrossPortfolioMarginConfig): number {
  const riskMatrixMarginRequirement = getRiskMatrixMarginRequirement(config)
  const openOrdersMarginRequirement = getOpenOrdersMarginRequirement()
  return config.modelParams.general.mmFactor * (riskMatrixMarginRequirement + openOrdersMarginRequirement)
}

export function getDeribitDiscountedEquity (config: DeribitCrossPortfolioMarginConfig): number {
  const equity = Object.entries(config.portfolio.currency).map(([currency, balance]) => {
    const currencyPair = `${currency}_usd`
    const currencyPrice = config.indexPrice[currencyPair]
    const haircut = config.modelParams.currency[currency].haircut
    return balance * currencyPrice * (1 - haircut)
  }).reduce((acc, val) => acc + val, 0)
  return equity
}
