export enum InputType {
  STRING,
  FLOAT,
  POS_FLOAT,
  POS_INTEGER,
  ACCOUNT,
  ALERT_TYPE,
  ALERT_SIDE,
  DELIVERY_METHOD
}

export interface PositionRow {
  id: string
  ticker: string
  quantity: number
  price: number
  unrealizedPnl: number
  settlementCurrencyId: string
  marginRequirementCoefficient: number
  multiplier: number
  priceDecimals: number
  quantityDecimals: number
  marketValue: number
  annualizedFundingRatePct: number
  ourAnnualizedFundingRatePct: number
  riskOffsetAmount: number
}

export interface CollateralRow {
  id: string
  ticker: string
  balance: number
  price: number
  haircut: number
  multiplier: number
  priceDecimals: number
  quantityDecimals: number
  marketValue: number
  encumberedBalance: number
}

export interface AlertRow {
  id: string
  account: string
  alertType: string
  alertSide: string
  alertSideSign: number
  alertDeliveryMethod: string
  deliveryIdentifier: string
  user: string
  userId: string
  currencyId: string
  movement: number | string
  healthThreshold: number
  cooldownMinutes: number
}
