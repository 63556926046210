import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import InsufficientPermissions from '../utils/InsufficientPermissions'
import usePermissions from '../../sdk/usePermissions'
import DeribitAccountBody from './DeribitAccountBody'

function CounterpartyBody (props: {
  setHasEdited: (hasEdited: boolean) => void
  shockModalOpen: boolean
  setShockModalOpen: (open: boolean) => void
}): React.JSX.Element {
  const {
    setHasEdited,
    shockModalOpen,
    setShockModalOpen
  } = props
  const permissionsContext = usePermissions()
  const { accountId } = useParams()

  if (!permissionsContext.hasDeribitAccess) {
    return <InsufficientPermissions/>
  }

  return (
    <Box sx={{ height: '93%' }}>
      {
        accountId === undefined
          ? <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h1>Select Account</h1>
          </Box>
          : <DeribitAccountBody
            accountId={accountId}
            setHasEdited={setHasEdited}
            shockModalOpen={shockModalOpen}
            setShockModalOpen={setShockModalOpen}
          />
      }
    </Box>
  )
}

export default CounterpartyBody
