import React, { type Key } from 'react'
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { ColumnDefinition, ObjectWithId, ValueCell } from '@r40cap/ui'

import { type InputType } from '../../types'

function AccountTableBody<R extends ObjectWithId<string>, O> (props: {
  rows: R[]
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof O
  ) => void
  columns: Array<ColumnDefinition<R, InputType, O, string>>
}): React.JSX.Element {
  const { rows, handleOpenEdit, columns } = props
  const { palette } = useTheme()

  return <TableBody sx={{ height: '100%' }}>
    {
      rows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            {columns.map((column) => (
              <ValueCell<R, any>
                column={column}
                item={row}
                key={column.id as Key}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={() => {
                  if (column.editId !== undefined && column.inputType !== undefined) {
                    handleOpenEdit(
                      row.id,
                      column.inputType,
                      column.label,
                      column.editId
                    )
                  }
                }}
              />
            ))}
          </TableRow>
        )
      })
    }
    {
      rows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>None</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

export default AccountTableBody
