import React, { type Key } from 'react'
import { TableCell, TableHead, TableRow, useTheme } from '@mui/material'
import { ColumnDefinition, HeaderCell, ObjectWithId, Order } from '@r40cap/ui'

interface SortableHeaderProps<R extends ObjectWithId<string>, O, I> {
  onRequestSort: (property: keyof R) => void
  order: Order
  orderBy: keyof R
  columns: Array<ColumnDefinition<R, I, O, string>>,
  aditionalEndingKeys?: readonly string[]
}

function SortableHeader<R extends ObjectWithId<string>, O, I> (props: SortableHeaderProps<R, O, I>): React.JSX.Element {
  const {
    onRequestSort,
    order,
    orderBy,
    columns,
    aditionalEndingKeys
  } = props
  const { palette } = useTheme()
  const usedEndPaddingKeys = aditionalEndingKeys ?? []

  const createSortHandler = (property: keyof R): void => {
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <HeaderCell<R, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.id as Key}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
        {
          usedEndPaddingKeys.map((key) => (
            <TableCell key={key} sx={{ width: '15%' }}/>
          ))
        }
      </TableRow>
    </TableHead>
  )
}

export default SortableHeader
