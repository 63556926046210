import React from 'react'
import { type Order } from '@r40cap/ui'

import { InputType } from '../../types'
import { NumberEditContent } from '../modals/inputs'

export function getModalContent<O> (
  inputType: InputType,
  label: string,
  editProperty: keyof O,
  closeModal: () => void,
  handleSubmission: (value: any, selectedIds: readonly string[], property?: keyof O) => void,
  newSelected: readonly string[],
  setSelected: (values: string[]) => void,
  initialValue?: string | null
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, newSelected, editProperty)
    setSelected([])
  }

  switch (inputType) {
    case InputType.FLOAT:
      content = <NumberEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
      break
    default:
      content = <NumberEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
        />
  }
  return content
}

export function getDecimals (price: number): [number, number] {
  let quantityDecimals: number = 0
  let priceDecimals: number = 0
  if (price < 0.0001) {
    quantityDecimals = 0
    priceDecimals = 6
  } else if (price < 0.01) {
    quantityDecimals = 0
    priceDecimals = 4
  } else if (price < 1) {
    quantityDecimals = 2
    priceDecimals = 2
  } else if (price < 10) {
    quantityDecimals = 2
    priceDecimals = 2
  } else if (price < 100) {
    quantityDecimals = 2
    priceDecimals = 2
  } else if (price < 10000) {
    quantityDecimals = 2
    priceDecimals = 0
  } else {
    quantityDecimals = 4
    priceDecimals = 0
  }
  return [quantityDecimals, priceDecimals]
}

export function getAbsoluteValueComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
) {
  return (a: Partial<{ [key in Key]: number | string | boolean | null }>, 
          b: Partial<{ [key in Key]: number | string | boolean | null }>) => {
    const aValue = Math.abs(a[orderBy] as number)
    const bValue = Math.abs(b[orderBy] as number)

    return order === 'desc'
      ? bValue - aValue
      : aValue - bValue
  }
}
